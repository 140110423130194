<template>
  <div>
    <Pane />

    <a-card class="container">
      <Header title="EPC项目预计总成本表" />

      <div class="content">
        <div class="title">
          <span class="value">项目信息</span>
        </div>

        <a-descriptions bordered size="small" :column="2">
          <a-descriptions-item>
            <div slot="label" class="center">合同编号</div>
            <div>
              <span v-if="selectedContract.name">{{
                selectedContract.code
              }}</span>
            </div>
          </a-descriptions-item>
          <a-descriptions-item>
            <div slot="label" class="center">合同名称</div>
            <div>
              {{ selectedContract.name }}
            </div>
          </a-descriptions-item>
          <a-descriptions-item>
            <div slot="label" class="center">总承包总价（元）</div>
            <div>
              <span>
                {{
                  typeof totalAmt === "number" ? totalAmt.toLocaleString() : ""
                }}
              </span>
            </div>
          </a-descriptions-item>
        </a-descriptions>

        <div class="title">
          <span class="value">金额明细</span>
        </div>

        <AmptList :totalList="amtTotalList" :disabled="true" />

        <div class="title">
          <span class="value">项目总支出</span>
        </div>

        <ExpList :list="expList" :all="expAll" :disabled="true" />

        <div class="title">
          <span class="value">项目利润</span>
        </div>

        <ProfitList :list="profitList" />

        <div class="title">
          <span class="value">预计总成本表</span>
        </div>

        <EstCostList :list="estCostList" />

        <div class="footer">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </div>
    </a-card>
  </div>
</template>
  
<script>
import Header from "./components/header";

import AmptList from "./components/amtList.vue";
import ExpList from "./components/expList.vue";
import ProfitList from "./components/profitList.vue";
import EstCostList from "./components/estCostList.vue";

import accurate from "accurate";

import { fetchDetail } from "./api";

export default {
  components: {
    Header,
    AmptList,
    ExpList,
    ProfitList,
    EstCostList,
  },
  data() {
    return {
      selectedContract: {},
      totalAmt: null,

      amtList: [], // 金额明细列表

      expList: [],

      estCostList: [],
    };
  },

  computed: {
    amtTotalList() {
      const arr = [...this.amtList];

      let amt = 0;
      let exTaxAmt = 0;
      arr.forEach((item) => {
        if (typeof item.amt === "number") {
          amt = accurate.add(amt, item.amt);
          exTaxAmt = accurate.add(exTaxAmt, this.getMoney(item));
        }
      });
      arr.push({
        amt,
        exTaxAmt,
      });
      return arr;
    },

    expAll() {
      if (this.expList.length > 0) {
        let amt = 0;
        let exTaxAmt = 0;
        this.expList.forEach((item) => {
          if (item.sort.indexOf(".") > 0 && typeof item.amt === "number") {
            amt = accurate.add(amt, item.amt);
            exTaxAmt = accurate.add(exTaxAmt, this.getMoney(item));
          }
        });
        return {
          sort: "合计",
          amt,
          exTaxAmt,
        };
      } else {
        return {
          sort: "合计",
          amt: 0,
          exTaxAmt: 0,
        };
      }
    },

    profitList() {
      const amtAll = this.amtTotalList[this.amtTotalList.length - 1];

      // 总金额减总成本
      const amt = accurate.subtract(amtAll.amt, this.expAll.amt);
      // 扣税总金额减扣税总成本
      const exTaxAmt = accurate.subtract(amtAll.exTaxAmt, this.expAll.exTaxAmt);

      let noDesignAmt = amtAll.amt; // 扣除了勘察设计费的总金额
      let noDesignExTaxAmt = amtAll.exTaxAmt; // 扣除了勘察设计费的不含税总金额
      const design1 = this.amtTotalList.find(
        (item) => item.name === "勘察设计费"
      );
      if (design1) {
        const { amt } = design1;
        noDesignAmt = accurate.subtract(noDesignAmt, amt ? amt : 0);
        noDesignExTaxAmt = accurate.subtract(
          noDesignExTaxAmt,
          this.getMoney(design1)
        );
      }
      console.log("design1", design1);
      console.log("noDesignAmt", noDesignAmt);
      console.log("noDesignExTaxAmt", noDesignExTaxAmt);

      let noDesignCostAmt = this.expAll.amt; // 扣除了勘察设计成本的总成本
      let noDesignCostExTaxAmt = this.expAll.exTaxAmt; // 扣除了勘察设计费的不含税总成本
      const design2 = this.expList.find(
        (item) => item.name === "勘察设计费分包"
      );
      if (design2) {
        noDesignCostAmt = accurate.subtract(
          noDesignCostAmt,
          this.getCategoryMoney(design2)
        );
        noDesignCostExTaxAmt = accurate.subtract(
          noDesignCostExTaxAmt,
          this.getCategoryMoneyWithoutTax(design2)
        );
      }
      console.log("design2", design2);
      console.log("noDesignCostAmt", noDesignCostAmt);
      console.log("noDesignCostExTaxAmt", noDesignCostExTaxAmt);

      // 不含设计费的 总金额减总成本
      const amt_no_design = accurate.subtract(noDesignAmt, noDesignCostAmt);
      // 不含设计费的 扣税总金额减扣税总成本
      const exTaxAmt_no_design = accurate.subtract(
        noDesignExTaxAmt,
        noDesignCostExTaxAmt
      );

      return [
        {
          name: "项目毛利润（含设计费）",
          unit: "元",
          amt,
          exTaxAmt,
        },
        {
          name: "项目毛利润（不含设计费）",
          unit: "元",
          amt: amt_no_design,
          exTaxAmt: exTaxAmt_no_design,
        },
        {
          name: "项目毛利率(%)（含设计费）",
          unit: "%",
          amt: accurate.multiply(accurate.divide(amt / amtAll.amt), 100),
          exTaxAmt: accurate.multiply(
            accurate.divide(exTaxAmt / amtAll.exTaxAmt),
            100
          ),
        },
        {
          name: "项目毛利率(%)（不含设计费）",
          unit: "%",
          amt: accurate.multiply(
            accurate.divide(amt_no_design / noDesignAmt),
            100
          ),
          exTaxAmt: accurate.multiply(
            accurate.divide(exTaxAmt_no_design / noDesignExTaxAmt),
            100
          ),
        },
      ];
    },
  },

  mounted() {
    fetchDetail({
      id: this.$route.query.id,
    }).then((res) => {
      const detail = res ?? {};
      this.selectedContract = {
        code: detail.contractCode,
        name: detail.contractName,
      };
      this.totalAmt = detail.totalAmt;

      if (Array.isArray(res.amtList)) {
        this.amtList = res.amtList;
      }
      if (Array.isArray(res.expList)) {
        this.expList = res.expList.map((item) => {
          return {
            ...item,
            sort: String(item.sort),
          };
        });
      }

      if (Array.isArray(res.estCostList)) {
        this.estCostList = res.estCostList.map((item) => {
          return {
            ...item,
            date: item.date,
            fileList:
              typeof item.attachments === "string"
                ? item.attachments.split(",")
                : [],
          };
        });
      }
    });
  },

  methods: {
    getMoney(text) {
      if (typeof text.amt === "number" && typeof text.taxRate === "number") {
        return accurate.expr(`(100 - ${text.taxRate}) * ${text.amt} * 0.01`);
      } else {
        return 0;
      }
    },

    // 获取这个分类下所有的
    getCategoryMoney(text) {
      const arr = this.expList.filter(
        (item) => item.sort.indexOf(text.sort + ".") === 0
      );

      let amt = 0;
      arr.forEach((item) => {
        if (typeof item.amt === "number") {
          amt = accurate.add(amt, item.amt);
        }
      });

      return amt;
    },

    getCategoryMoneyWithoutTax(text) {
      const arr = this.expList.filter(
        (item) => item.sort.indexOf(text.sort + ".") === 0
      );

      let exTaxAmt = 0;

      arr.forEach((item) => {
        if (typeof item.amt === "number") {
          exTaxAmt = accurate.add(exTaxAmt, this.getMoney(item));
        }
      });

      return exTaxAmt;
    },
  },
};
</script>
  
  
  
<style lang="less" scoped>
.content {
  padding: 0 80px;

  .title {
    margin-bottom: 12px;
    margin-top: 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .value {
      font-weight: bold;
      color: #1890ff;
      font-size: 16px;
    }
  }
}

.footer {
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>